import React from 'react';
import GeoImage from './GeoImage';
import ImageMap from './ImageMap';

const bounds = [-77.050872, 38.901924, -77.049329, 38.903152];

class People extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: []
        };
    }

    render() {
        const { selectedPerson } = this.props;
        const { features } = this.state;
        const imageURL = selectedPerson && `..${selectedPerson.node.publicURL}`;
        const name = selectedPerson && selectedPerson.node.name;
        const color = selectedPerson && selectedPerson.node.color;
        return (
            <div>
                <GeoImage image={imageURL} backgroundColor={[106, 67, 132]} bounds={bounds} onFeatures={pixelFeatures => this.setState({ features: pixelFeatures })} />
                <ImageMap image={imageURL} selection={name} color={color} name={name} features={features} />
            </div>
        );
    };
}

export default People;
