import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { color, interpolateRainbow } from 'd3'
import People from '../components/People'
import levelUp from '../assets/images/levelup.jpg'
import companyImage from '../assets/images/company.jpg'
import teamImage from '../assets/images/looking_for_idea_with_team.svg'
import slidingDoors from '../assets/images/community/sliding_doors.jpg'
import wineToWater from '../assets/images/community/wine_to_water.png'
import worldTeam from '../assets/images/community/worldteam.jpg'
import RandomColorGenerator from '../components/RandomColorGenerator'

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const personColor = new RandomColorGenerator(Math.random(), interpolateRainbow);
const Company = ({data}) => {
    const { images, partners, community } = data;
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [headshots, setHeadshots] = useState([]);
    const peopleImages = images.edges.filter(image => image.node.relativePath.indexOf('headshots') > -1);
    const partnerImages = partners.edges.filter(image => image.node.relativePath.indexOf('partners') > -1);
    const communityImages = community.edges.filter(image => image.node.relativePath.indexOf('community') > -1);
    useEffect(() => {
        const shuffledPeople = shuffleArray([...peopleImages]).map(image => {
            const personColorVal = personColor.next(image.node.name);
            const personColorObj = color(personColorVal);
            const { r, g, b } = personColorObj;
            image.node.color = [r, g, b];
            return image;
        });
        setHeadshots(shuffledPeople);
        setSelectedPerson(shuffledPeople[0]);
    }, []);
    return (
        <Layout>
            <Helmet>
                <title>Company</title>
                <meta name="description" content="Landing Page" />
                <link rel="preload" as="image" href={companyImage} />
            </Helmet>
            <section id="banner" className="company">
                <div className="inner">
                    <header className="major">
                        <h1>Company</h1>
                    </header>
                    <div className="content">
                        <p>Be Brave. Be Smart. Be Focused.</p>
                    </div>
                </div>
            </section>
            <div id="main">
                <section id="one" style={{ position: 'relative' }}>
                    <div className="inner">
                        <header className="major">
                            <h2>About Us</h2>
                        </header>
                        <p>Black Cape is a Service-Disabled Veteran-Owned Small Business (SDVOSB) with expertise in developing Mission Applications and applying Machine Learning and Artificial Intelligence to hard problems in the Government and Commercial Sectors.  We have a proven track record and decades of experience delivering solutions that meet end user needs.</p>
                    </div>
                </section>
                <section id="one" style={{ position: 'relative' }}>
                    <div className="inner">
                        <header className="major">
                            <h2>People</h2>
                        </header>
                        <p><span className="image left"><img src={teamImage} alt="" style={{ minWidth: '100px' }} /></span>We are a multi-disciplinary group of software engineers, data scientists, machine-learning practitioners, systems engineers, and leaders.  We enjoy working together as a close-knit team to build technology that solves hard challenges.  We are excited to be a trusted partner with many organizations within the US Government.</p>
                        <p>We are small enough that every member of Black Cape is a name not a number.  We believe that innovation is a team-sport, and we work hard to create a respectful and fun environment where everyone is supported to do their very best work. </p>
                        <ul className="actions"><li><a href="/careers" className="button special">Join Us</a></li></ul>
                    </div>
                </section>
                <section>
                    <div className="content" style={{ position: 'relative', height: '400px'}}>
                        <People selectedPerson={selectedPerson} />
                    </div>
                    <div className="content photo-grid" style={{ overflowX: 'auto', height: '150px' }}>
                        <div className="grid-wrapper" style={{ display: 'grid', gridGap: '0', gridTemplateColumns: 'repeat(1000, 1fr)' }}>
                            {headshots.map((image, index) => (
                                <div key={`img-${index}`} className={`col-1 ${selectedPerson && selectedPerson.node.publicURL === image.node.publicURL ? 'selected' : ''}`} style={{ cursor: 'pointer' }}>
                                    <span className="image" onClick={() => setSelectedPerson(image)}>
                                        <Img
                                            fixed={image.node.childImageSharp.fixed}
                                        />
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="two" className="" style={{ background: 'linear-gradient(180deg, rgba(0,0,0,0), #222)' }}>
                    <section className="inner" style={{ background: 'transparent' }}>
                        <div className="content" style={{ width: '100%' }}>
                            <div className="inner">
                                <header className="major">
                                    <h3>Professional Development</h3>
                                </header>
                                <p>We are passionate about software engineering and are always working to grow our skills. Our engineers are happiest when they are working with their colleagues on meaningful projects, using the latest technologies, and constantly growing their skills. Professional development is a constant focus on Black Cape and we invest time and resources to ensure the team is mentored on new technology that is relevant to our mission.</p>
                                <h4>Professional Development Plan</h4>
                                <p>Every member of the team works with a leader to co-author a one-page Professional Development Plan that defines how Black Cape will support their growth during the year. The one pager is revisited quarterly to check in on progress and ensure that people are working towards their tech and management goals.</p>
                                <h4>LevelUp! Sessions</h4>
                                <p><span className="image left"><img src={levelUp} /></span>We also host quarterly "LevelUp!" sessions where we give tech-talks on topics related to Machine Learning and Data Analytics. Machine learning is a huge topic, so members of the team create presentations, code, and documentation that everyone can benefit from.</p>
                                <h4>Lunch &amp; Learns</h4>
                                <p>Engineers host informal "Lunch &amp; Learn" sessions to share tactics, techniques, and procedures related to technologies that we are using. Topics can range from ways to optimize your IDE workflow to recent project successes and how they can be applied elsewhere.</p>
                                <p>As part of our Professional Development Process, Black Cape invests time and resources to ensure the team is mentored on new technology that is relevant to our mission.</p>
                            </div>
                        </div>
                    </section>
                    <section className="inner" style={{ background: 'transparent' }}>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Partners</h3>
                                </header>
                                <p>In association with <a href="https://www.carahsoft.com/" target="_blank" rel="noopener noreferrer">Carahsoft</a>, Black Cape has partnered with a variety of premier technology vendors in order to deliver exceptional solutions to commercial and government clients.</p>
                            </div>
                        </div>
                        <div className="content partner-grid" style={{ background: '#fff', position: 'relative', overflowX: 'auto', width: '100%', padding: '2rem'}}>
                            <div className="grid-wrapper" style={{ display: 'grid', gridGap: '2rem', gridTemplateColumns: 'repeat(4, 0fr)' }}>
                                {partnerImages.map((partnerImage, index) => {
                                    return (<div key={`partner-${index}`} style={{ width: '180px', height: '80px' }}>
                                            <Img
                                                fluid={partnerImage.node.childImageSharp.fluid}
                                                style={{ width: '180px', height: '80px'}}
                                            />
                                    </div>);
                                })}
                            </div>
                        </div>
                    </section>
                    <section className="inner" style={{ background: 'transparent' }}>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Community</h3>
                                </header>
                                <p>Black Cape is proud to support the following charitable organizations:</p>
                                <p>
                                    <a className="charity-link" href="https://winetowater.org" target="_blank" rel="noopener noreferrer"><img src={wineToWater} style={{ width: '200px' }} /><span>Wine to Water</span></a>
                                    <a className="charity-link" href="https://sdsquared.org" target="_blank" rel="noopener noreferrer"><img src={slidingDoors} style={{ width: '200px' }} /><span>Sliding Doors</span></a>
                                    <a className="charity-link" href="https://www.wtsevent.org/faceofamerica/" target="_blank" rel="noopener noreferrer"><img src={worldTeam} style={{ width: '200px'}} /><span>World T.E.A.M/Face of America</span></a>
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
  query {
    images: allFile(filter: { extension: { eq: "jpg" } }) {
        edges {
          node {
            publicURL
            name
            relativePath
            childImageSharp {
                fixed(width: 150, height: 150) {
                    ...GatsbyImageSharpFixed
                }
            }
          }
        }
    },
    partners: allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            publicURL
            name
            relativePath
            childImageSharp {
                fluid(maxHeight: 80, maxWidth: 180, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
    },
    community: allFile(filter: { extension: { regex: "/(jpg|png)/" } }) {
        edges {
          node {
            publicURL
            name
            relativePath
            childImageSharp {
                fluid(maxHeight: 200, maxWidth: 200, fit: CONTAIN, background: "rgba(255,255,255,1)") {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
    }
  }
`
export default Company;