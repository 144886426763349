import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/#focus">Focus Areas</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/#products">Products</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/company">Company</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/news">News</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/blog">Blog</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><a href="/careers" className="button special fit">Careers</a></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu}>Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
