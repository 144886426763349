import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://twitter.com/blackcapeio" className="icon alt fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/blackcape.io/" className="icon alt fa-facebook" target="_blank" rel="noopener noreferrer"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/blackcape.io/?hl=en" className="icon alt fa-instagram" target="_blank" rel="noopener noreferrer"><span className="label">Instagram</span></a></li>
                <li><a href="https://www.github.com/black-cape" className="icon alt fa-github" target="_blank" rel="noopener noreferrer"><span className="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/company/black-cape" className="icon alt fa-linkedin" target="_blank" rel="noopener noreferrer"><span className="label">LinkedIn</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; {`${[...new Set([2019, new Date().getFullYear()])].join(' - ')}`}</li><li>Black Cape, Inc.</li>
            </ul>
        </div>
    </footer>
)

export default Footer
