import React from 'react';
import { scaleLinear } from 'd3';

class GeoImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            imageLoaded: false,
        };
    }

    componentDidMount() {
        const { image } = this.props;
        if (image) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { image, bounds } = this.props;
        if (image && image !== prevProps.image || bounds !== prevProps.bounds) {
            this.loadData();
        }
    }

    isBackground = (colorVal) => {
        const [bR, bG, bB] = this.props.backgroundColor;
        const delta = 0.3;
        const lower = 1 - delta;
        const upper = 1 + delta;
        return (colorVal[0] > lower * bR && colorVal[0] < upper * bR) && (colorVal[1] > lower * bG && colorVal[1] < upper * bG) && (colorVal[2] > lower * bB && colorVal[2] < upper * bB);
    };

    drawImage = (width, height) => {
        const ctx = this.canvas.getContext('2d');
        this.canvas.width = width;
        this.canvas.height = height;
        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;
        ctx.drawImage(this.image, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;
        const pixels = [];
        for (let r = 0; r < height; r++) {
            for (let c = 0; c < width; c++) {
                const index = (c + (r * width)) * 4;
                const nw = [this.scaleX(c), this.scaleY(r)];
                const ne = [this.scaleX(c + 1), this.scaleY(r)];
                const se = [this.scaleX(c + 1), this.scaleY(r + 1)];
                const sw = [this.scaleX(c), this.scaleY(r + 1)];
                const colorVal = [
                    data[index],
                    data[index + 1],
                    data[index + 2],
                    data[index + 3],
                ];
                const isBackground = this.props.backgroundColor && this.isBackground(colorVal);
                pixels.push({
                    properties: {
                        center: [(nw[0] + se[0])/2, (nw[1] + se[1])/2],
                        color: colorVal,
                        background: isBackground
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [[
                            nw,
                            ne,
                            se,
                            sw,
                            nw,
                        ]]
                    },
                    type: 'Feature',
                });
            }   
        }

        this.props.onFeatures(pixels);
    };

    loadData = () => {
        if (this.props.image && this.props.bounds && this.canvas) {
            this.image = this.image || new Image();
            this.image.onload = () => {
                const [minLon, minLat, maxLon, maxLat] = this.props.bounds;
                const { width, height } = this.image;
                this.scaleX = scaleLinear().domain([0, width]).range([minLon, maxLon]);
                this.scaleY = scaleLinear().domain([0, height]).range([maxLat, minLat]);
                this.drawImage(width, height);
                this.setState({ width, height, imageLoaded: true });
            };
            this.image.src = this.props.image;
        }
    }

    render() {
        const { width, height } = this.state; 
        return (
            <canvas style={{ display: 'none', width, height }} ref={el => this.canvas = this.canvas || el} />
        )
    }
};

export default GeoImage;