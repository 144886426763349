import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logo from '../assets/images/bclogo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/" className="logo" style={{ margin: '0px', padding: '0px', position: 'relative' }}>
            <div className="triangle header-triangle" />
            {/*<strong>Black</strong> <span>Cape</span>*/}
            <img className="header-logo" src={logo} />
        </Link>
        <nav>
            <a className="menu-link" onClick={props.onToggleMenu}>Menu</a>
        </nav>
    </header>
);

Header.propTypes = {
    onToggleMenu: PropTypes.func
};

export default Header;
