import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticleGrid from '../components/ArticleGrid'

const Blog = () => {
    return (
        <Layout>
            <Helmet>
                <title>Blog</title>
                <meta name="description" content="Black Cape Blog" />
            </Helmet>
            <section id="banner" className="blog">
                <div className="inner">
                    <header className="major">
                        <h1>Blog</h1>
                    </header>
                    <div className="content">
                        <p>Thoughts on Technology from the Black Cape Staff</p>
                    </div>
                </div>
            </section>
            <div id="main">
                <section id="one">
                    <div className="inner">
                        <ArticleGrid articleType={'blog'} />
                    </div>
                </section>
            </div>
        </Layout>
    );
};
export default Blog;