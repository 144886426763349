import React from 'react';
import DeckGL, {FlyToInterpolator, PointCloudLayer, TextLayer, GeoJsonLayer} from 'deck.gl';
import ReactMapGL, {StaticMap} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { color, interpolateRainbow } from 'd3';
import { along, bbox, bboxPolygon, buffer, featureCollection, length, lineString } from '@turf/turf';
import RandomColorGenerator from './RandomColorGenerator';
import * as highways from '../assets/data/dchighways.json';

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = '';
const TEXT_DISTANCE = 0.01;

// Initial viewport settings
const initialViewState = {
  // longitude: -77.0230,
  // latitude: 38.8896,
  longitude: -77.0501005,
  latitude: 38.902538,
  zoom: 16,
  pitch: 0,
  bearing: 0,
  transitionDuration: 3000,
  transitionInterpolator: new FlyToInterpolator(),
  minZoom: 17,
  maxZoom: 22
};

let layerConfig = {};

const nameColor = new RandomColorGenerator(Math.random(), interpolateRainbow);
class ImageMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buffers: [],
            features: [], //props.features, //|| [],
            viewState: initialViewState,
            pointFeatures: [],
            pointFeaturePaths: []
        };
    }

    componentDidMount() {
      layerConfig = {
         "version": 8,
         "sources": {
           "impervious": {
              "type": "vector",
              "tiles": [`${window.location.protocol}//${window.location.hostname}:${window.location.port}/dc-tiles/{z}/{x}/{y}.pbf`],
              "minzoom": 14,
              "maxzoom": 18
           }
         },
         "layers": [{
             "id": "background",
             "type": "background",
             "paint": {
                 "background-color": "#111"
             }
         }, {
           "id": "dc-fill",
           "type": "fill",
           "source": "impervious",
           "source-layer": "impervious",
           "paint": {
              "fill-color": 'rgb(122, 0, 234)',
              "fill-opacity": 0.05
           }
        }, {
           "id": "dc-fill-extrusion",
           "type": "fill-extrusion",
           "source": "impervious",
           "source-layer": "impervious",
           "paint": {
              "fill-extrusion-color": 'rgb(122, 0, 234)',
              "fill-extrusion-height": 20,
              "fill-extrusion-opacity": 0.1,
              "fill-extrusion-base": 0
           },
           "filter": ["any", ["==", "DESCRIPTIO", "Building"], ["==", "DESCRIPTIO", "Memorial"]]
        }]
     };
       this.setupHighwayText();
    }

    componentDidUpdate(prevProps) {
        const { features, image, selection } = this.props;
        if (features && ((this.state.features.length === 0 && features !== this.state.features) || (image && image !== prevProps.image))) {
            this.setState({
                features,
            });
        }

        if (features && features !== prevProps.features) {
           /*
           const hull = convex({
              type: 'FeatureCollection',
              features: features.filter(f => !f.properties.background)
           }, {
              maxEdge: 1
           });
           */
           const hull = bboxPolygon(bbox(featureCollection(features)));
           const buffers = [hull].concat([0.005].map(val => buffer(hull, val)));
           this.setState({
              buffers
           });
        }

        if (selection && selection !== prevProps.selection) {
           this.flyRotate(null);
        }
    }

    componentWillUnmount() {
      if (this.rotationId) {
         clearInterval(this.rotationId);
      }
    }

    setupHighwayText = () => {
      const pointFeatures = [];
      const pointFeaturePaths = [];
      const pointFeatureText = [];
      highways.features.map(feature => {
          feature.geometry.coordinates.forEach(line => {
              const lineFeature = lineString(line);
              const lineLength = length(lineFeature);
              let start = 0;
              while (start < lineLength) {
                  const pointFeature = along(lineFeature, start);
                  const text = Math.random().toFixed(0);
                  pointFeatureText.push(text);
                  pointFeature.properties.text = text;
                  pointFeatures.push(pointFeature);
                  start += TEXT_DISTANCE;
              }
          });
      });
      this.setState({
          pointFeatures,
          pointFeaturePaths
      });
      this.pointFeatureText = pointFeatureText;
    }

    flyToPoint = ({ longitude, latitude, zoom, pitch, bearing }) => {
        const newViewState = { ...this.state.viewState };
        newViewState.longitude = longitude;
        newViewState.latitude = latitude;
        newViewState.zoom = zoom;
        newViewState.pitch = pitch;
        newViewState.bearing = bearing;
        newViewState.transitionDuration = 1500;
        newViewState.transitionInterpolator = new FlyToInterpolator();
        this.setState({
            viewState: newViewState
        });
    };

    flyRotate = (bearing) => {
      this.flyToPoint({
            longitude: -77.0501005,
            latitude: 38.902538,
            zoom: 17,
            pitch: 10,
            bearing: bearing !== null ? bearing : Math.random() * 60 - 30
      });
    };

    startRotation = () => {
      this.rotationId = setInterval(this.flyRotate, 5000);
    };

    startTour = () => {
      this.flyRotate(0);
      //setTimeout(this.startRotation, 1000);
    };

    onDeckRef = ref => {
        // save a reference to the Deck instance
        this.deck = ref && ref.deck;
    };

    onMapRef = ref => {
      // save a reference to the mapboxgl.Map instance
      this.map = ref && ref.getMap();
      if (this.map) {
         this.map.on('load', () => {
            this.startTour();
         });
      }
    };

    onViewStateChange = ({viewState}) => {
        this.setState({ viewState });
    };

    getNameColor = name => {
      const colorVal = nameColor.next(name);
      const colorObj = color(colorVal);
      return [colorObj.r, colorObj.g, colorObj.b, 255];
    };

    getColor = (f, fInfo) => {
      const personColor = this.props.color || [122, 0, 234];
      const feature = this.props.features[fInfo.index];
      const colorVal = [...feature.properties.color];
      const isBackground = feature.properties.background;
      if (isBackground) {
         colorVal[0] = personColor[0]
         colorVal[1] = personColor[1]
         colorVal[2] = personColor[2]
      }
      return colorVal;
    };

    renderLayers = (viewState) => {
         const { zoom } = viewState;
         const { name } = this.props;
         const { buffers } = this.state;
         const layers = [
            new TextLayer({
                id: 'image-text',
                data: this.state.features,
                getPosition: f => f.properties.center,
                getText: (f, fInfo) => {
                   const feature = this.props.features[fInfo.index];
                   const isBackground = feature.properties.background;
                   const char = name ? name.charAt(fInfo.index % name.length) : '?';
                   return isBackground ? '/' : char;
                },
                getColor: this.getColor,
                getSize: 1,
                sizeUnits: 'meters',
                getAngle: 0,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'center',
                updateTriggers: {
                   getText: [this.props.features],
                   getColor: [this.props.features]
                },
                transitions: {
                   getColor: {
                      duration: 300
                   },
                   getText: {
                      duration: 300
                   }
                },
                visible: zoom >= 20
            }),
            new GeoJsonLayer({
               id: 'buffers',
               data: buffers,
               stroked: true,
               filled: false,
               getLineColor: this.props.color,
            }),
            new PointCloudLayer({
               id: 'points',
               data: this.state.features,
               getPosition: (f, fInfo) => {
                  const center = f.properties.center;
                  const feature = this.props.features[fInfo.index];
                  const isBackground = feature.properties.background;
                  //return [center[0] + 0.000001 * Math.random(), center[1] + 0.000001 * Math.random(), isBackground ? ((fInfo.index / 300) % 2) * -2: 0];
                  return [center[0], center[1], isBackground ? 2 + -1 * (fInfo.index % 2) - ((fInfo.index / 300) % 2) : 2];
               },
               getColor: this.getColor,
               pointSize: 3,
               updateTriggers: {
                  getColor: [this.props.features],
                  getPosition: [this.props.features]
               },
               transitions: {
                  getColor: {
                     duration: 600
                  }
               },
               material: {
                  ambient: 0.35,
                  diffuse: 0.6,
                  shininess: 256,
                  specularColor: [122, 0, 234]
               },
               visible: zoom < 20
            }),
            new TextLayer({
               id: 'point-text',
               data: this.state.pointFeatures,
               getPosition: f => f.geometry.coordinates,
               getText: (f, fInfo) => this.pointFeatureText[(fInfo.index + ~~(viewState.zoom * 100)) % this.pointFeatureText.length], // f.properties.text,
               getColor: this.props.color,
               getSize: 16,
               getAngle: 0,
               getTextAnchor: 'middle',
               getAlignmentBaseline: 'center',
               transitions: {
                   getText: {
                       duration: 300,
                   }
               },
               updateTriggers: {
                   getText: [this.state.time, viewState.zoom]
               },
               //visible: viewState.zoom > 16
            }),
        ];
        return layers;
    };

    getDeckColor = (colorVal, opacity) => {
        const colorObj = color(colorVal);
        return [colorObj.r, colorObj.g, colorObj.b, opacity || 255];
    };

    render() {
        const { viewState } = this.state;
        const layers = this.renderLayers(viewState);

        return (
            <DeckGL
                ref={this.onDeckRef}
                viewState={viewState}
                onViewStateChange={this.onViewStateChange}
                controller={true}
                layers={layers}
            >
                <StaticMap
                    ref={this.onMapRef}
                    mapStyle={layerConfig}
                    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                />
            </DeckGL>
        );
    }
}   

export default ImageMap;